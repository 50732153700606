import { TranslateLoader } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';

/**
 * Custom TranslateLoader implementation that uses Webpack dynamic import
 */
export class I18TranslateLoader implements TranslateLoader {
  public getTranslation(lang: string): Observable<any> {
    // Using Webpack's dynamic import to load translation files dynamically, this avoid caching issues
    return from(import(`../../assets/i18n/${lang}.json`).then((res) => res.default));
  }
}
